<template>
  <div class="w-full">
    <label>Empfänger:</label>
    <input
      v-model="newMail.recipient"
      class="border-2 border-gray-700 bg-gray-800 text-white px-2 py-1 w-full"
    />
    <label>Betreff:</label>
    <input
      v-model="newMail.subject"
      class="border-2 border-gray-700 bg-gray-800 text-white px-2 py-1 w-full"
    />
    <label>Nachricht:</label>
    <textarea
      v-model="newMail.message"
      rows="5"
      class="border-2 border-gray-700 bg-gray-800 text-white px-2 py-1 w-full"
    />
    <div class="w-full text-right">
      <button v-if="!sent" @click="sendMail()">Senden</button>
      <span
        class="font-bold text-green-500 border-2 border-green-500 px-2 py-1"
        v-if="sent"
        >Erfolgreich gesendet!</span
      >
    </div>
    <span class="font-bold">Bisheriger E-Mail Verlauf:</span>
  </div>
</template>

<script>
import API from "@/API";

export default {
  name: "SendMail",
  emits: ["sentMail"],
  props: {
    mail: Object,
  },
  data() {
    return {
      sent: false,
      newMail: {
        recipient: this.mail.replyTo,
        message: this.mail.ai_response,
        subject: "Re: " + this.mail.subject,
        replyToMail: this.mail.id,
      },
    };
  },
  mounted() {
    if(!this.mail.replyTo){
      if(this.mail.from_name){
        this.newMail.recipient = this.mail.from_name + ' <' + this.mail.from_email + '>';
      }else{
        this.newMail.recipient = this.mail.from_email;
      }
    }
  },
  methods: {
    sendMail() {
      API.sendMail(this.mail.belongsToMailbox, this.newMail, (res, status) => {
        if (status == 200) {
          this.sent = true;
          setTimeout(() => {
            this.$emit("sentMail");
            this.sent = false;
            this.newMail = {
              message: "",
            };
          }, 3000);
        }
      });
    },
  },
};
</script>