<template>
  <div class="bg-black/90 relative">
   
    
    <div class="h-full pb-11 overflow-auto">
      <MailListItem
        v-for="mail in mails"
        :mail="mail"
        :key="mail"
        @updatedEntry="getMails()"
      />
    </div>
  </div>
</template>

<script>
import Tab from "@/components/uicomponents/Tab.vue";
import MailListItem from "@/components/home/MailListItem.vue";
import API from "@/API";
export default {
  name: "Sent",
  components: {
    Tab,
    MailListItem,
  },
  props: {
    mailboxes: Array,
    currentMailbox: Object,
    keywords: String
  },
  data() {
    return {
      dragging: {},
      currentCategory: {},
      categories: [],
      showEditCategoryPopup: false,
      editedCategory: {},
      mails: [],
    };
  },
  methods: {
    getMails() {
      let vm = this;
      API.getMails(
        this.currentMailbox.id,
        {incoming: false, search: this.keywords},
        (res, status) => {
          if (status == 200) {
            vm.mails = res;
          }
        }
      );
    },
    
  },
  watch: {
    currentMailbox: function () {
      this.getMails();
    },
    keywords: function () {
      this.getMails();
    },
  },
  mounted() {
    this.getMails();
  },
  
};
</script>