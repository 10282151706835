import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faBars, faBrain, faCog, faEnvelope, faEnvelopeOpen, faInbox, faPaperPlane, faPaperclip, faPen, faPlus, faRefresh, faReply, faRightFromBracket, faShare, faTrashArrowUp, faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons'

library.add(faBars,faInbox,faPaperPlane,faRightFromBracket,faCog,faPlus,faEnvelope,faReply,faXmark,faBrain,faPen,faTrashCan,faTrashArrowUp, faShare, faPaperclip, faRefresh, faEnvelopeOpen)
const app=createApp(App)
app.component('icon', FontAwesomeIcon)
app.use(router)
app.mount('#app')
