import { get, post, put, del } from "./lib/http";

export default {
    login(ott, cb) {
        post("/auth/login", { ott:ott }, cb)
    },
    logout(cb) {
        post("/auth/logout", {}, cb)
    },
    requestOTT(mail, code, cb){
        post("/auth/requestOTT", { mail:mail, code:code }, cb)
    },
    getMailboxes(cb) {
        get("/mailboxes", cb)
    },
    testMailbox(type, mailbox, cb) {
        post("/mailboxes/test/" + type, mailbox, cb)
    },
    sendMail(mailbox_id, mail, cb) {
        post("/mailboxes/" + mailbox_id + "/mails", mail, cb)
    },
    saveMailbox(mailbox, cb) {
        if (mailbox.id) {
            put("/mailboxes/" + mailbox.id, mailbox, cb)
        } else {
            post("/mailboxes", mailbox, cb)
        }
    },
    deleteMailbox(mailbox, cb) {
        del("/mailboxes/" + mailbox.id, {}, cb)
    },
    getMailboxStats(mailbox, cb){
        get("/mailboxes/"+mailbox.id+"/stats", cb)
    },
    getMails(mailbox_id, filter, cb) {
        let getUri = "?"
        Object.keys(filter).forEach(key => {
            if (getUri != "?") getUri += "&"
            getUri += `${key}=${filter[key]}`
        })
        get("/mailboxes/" + mailbox_id + "/mails" + getUri, cb)
    },
    getCategories(mailbox_id, cb) {
        get("/mailboxes/" + mailbox_id + "/categories", cb)
    },
    modifyCategory(mailbox_id, category, cb) {
        if (category.id) {
            put("/mailboxes/" + mailbox_id + "/categories/" + category.id, category, cb)
        } else {
            post("/mailboxes/" + mailbox_id + "/categories", category, cb)
        }
    },
    analyzeMail(mail, cb) {
        post("/mailboxes/" + mail.belongsToMailbox + "/mails/" + mail.id + "/analyze", {}, cb)
    },
    updateMail(mail, update, cb) {
        put("/mailboxes/" + mail.belongsToMailbox + "/mails/" + mail.id, update, cb)
    }
}