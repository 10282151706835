<template>
  <div class="h-full w-full bg-gray-900">
    <Tab title="Postfächer" :selected="selectedTab" :editable="false" />
    <Tab title="Benachrichtigungen" :selected="selectedTab" :editable="false" />
    <div
      v-if="selectedTab.name === 'Postfächer'"
      class="overflow-y-auto h-full pb-20"
    >
      <div class="p-2" v-for="mailbox in editableMailboxes" :key="mailbox">
        <input
          v-model="mailbox.name"
          placeholder="Namen eingeben"
          class="underline bg-gray-900 text-white py-1 w-full text-3xl font-bold"
          :class="{ '!text-primary': mailbox.default }"
        />
        <div v-if="mailbox.authType == 'imap'">
          <span class="my-2 font-bold text-lg">Posteingang (IMAP)</span>
          <div class="grid grid-cols-2">
            <div class="pr-4">
              <label>Server:</label>
              <input
                v-model="mailbox.imap.server"
                class="w-full bg-gray-800 border-2 border-black inline-block px-2 py-1"
              />
            </div>
            <div class="pr-4">
              <label>Port:</label>
              <input
                v-model="mailbox.imap.port"
                placeholder="993"
                class="w-full bg-gray-800 border-2 border-black inline-block px-2 py-1"
              />
            </div>

            <div class="pr-4">
              <label>Username:</label>
              <input
                v-model="mailbox.imap.username"
                class="w-full bg-gray-800 border-2 border-black inline-block px-2 py-1"
              />
            </div>
            <div class="pr-4">
              <label>Passwort:</label>
              <input
                v-model="mailbox.imap.password"
                type="password"
                placeholder="Unverändert wenn leer"
                class="w-full bg-gray-800 border-2 border-black inline-block px-2 py-1"
              />
            </div>
          </div>
          <div class="w-full text-right pr-4 pt-2">
            <button
              v-if="tested != mailbox"
              :class="{ 'text-gray-300 border-gray-300': testing }"
              @click="testMailbox(mailbox, 'imap')"
            >
              Testen
            </button>
            <span
              v-if="!testError && tested == mailbox && testType === 'imap'"
              class="font-bold border-2 border-green-500 text-green-500 px-2 py-1"
              >Erfolgreich!</span
            >
            <span
              v-if="testError && tested == mailbox && testType === 'imap'"
              class="font-bold border-2 border-red-500 text-red-500 px-2 py-1"
              >{{ testError }}</span
            >
          </div>
          <span class="my-2 font-bold text-lg">Postausgang (SMTP)</span>
          <div class="grid grid-cols-2">
            <div class="pr-4">
              <label>Server:</label>
              <input
                v-model="mailbox.smtp.server"
                class="w-full bg-gray-800 border-2 border-black inline-block px-2 py-1"
              />
            </div>
            <div class="pr-4">
              <label>Port:</label>
              <input
                v-model="mailbox.smtp.port"
                placeholder="465"
                class="w-full bg-gray-800 border-2 border-black inline-block px-2 py-1"
              />
            </div>

            <div class="pr-4">
              <label>Username:</label>
              <input
                v-model="mailbox.smtp.username"
                class="w-full bg-gray-800 border-2 border-black inline-block px-2 py-1"
              />
            </div>
            <div class="pr-4">
              <label>Passwort:</label>
              <input
                v-model="mailbox.smtp.password"
                type="password"
                placeholder="Unverändert wenn leer"
                class="w-full bg-gray-800 border-2 border-black inline-block px-2 py-1"
              />
            </div>
            <div class="pr-4">
              <label>Absenderadresse:</label>
              <input
                v-model="mailbox.from_email"
                :placeholder="mailbox.smtp.username"
                class="w-full bg-gray-800 border-2 border-black inline-block px-2 py-1"
              />
            </div>
            <div class="pr-4">
              <label>Absendername:</label>
              <input
                v-model="mailbox.from_name"
                :placeholder="mailbox.smtp.username"
                class="w-full bg-gray-800 border-2 border-black inline-block px-2 py-1"
              />
            </div>
            <div class="p-2 mt-4">
              <input
                :id="mailbox.id + 'tls'"
                v-model="mailbox.smtp.starttls"
                type="checkbox"
                class="bg-black p-3"
              /><label :for="mailbox.id + 'tls'">STARTTLS</label>
            </div>
          </div>
          <div class="w-full text-right pr-4 pt-2">
            <button
              class="text-red-500 border-red-500"
              :class="{
                'text-gray-300 border-gray-300': saving === mailbox,
                'text-red-500 border-red-500': saved == mailbox,
              }"
              @click="deleteMailbox(mailbox)"
            >
              Löschen
            </button>
            <button
              v-if="mailbox.id && !mailbox.default"
              :class="{ 'text-gray-300 border-gray-300': testing }"
              @click="setDefault(mailbox)"
            >
              Als Standard festlegen
            </button>
            <button
              v-if="tested != mailbox"
              :class="{ 'text-gray-300 border-gray-300': testing }"
              @click="testMailbox(mailbox, 'smtp')"
            >
              Testen
            </button>
            <span
              v-if="!testError && tested == mailbox && testType === 'smtp'"
              class="font-bold border-2 border-green-500 text-green-500 px-2 py-1"
              >Erfolgreich!</span
            >
            <span
              v-if="testError && tested == mailbox && testType === 'smtp'"
              class="font-bold border-2 border-red-500 text-red-500 px-2 py-1"
              >{{ testError }}</span
            >
            <button
              :class="{
                'text-gray-300 border-gray-300':
                  saving === mailbox || mailbox.name.trim() == '',
                'text-green-500 border-green-500': saved == mailbox,
              }"
              @click="saveMailbox(mailbox)"
            >
              Speichern
            </button>
          </div>
        </div>
        <div v-else-if="mailbox.authType == 'google'">
          <span class="text-gray-300">Via Google</span>
          <div class="w-full grid grid-cols-2 mt-2">
            <div class="block pr-4">
              <label class="block">Absenderadresse:</label>
              <span
                class="px-2 mt-1 inline-block py-1 border-2 border-gray-700 w-full"
                >{{ mailbox.from_email }}</span
              >
            </div>
            <div class="block pr-4">
              <label class="block">Absendername:</label>
              <input
                v-model="mailbox.from_name"
                placeholder="Absendername (optional)"
                class="w-full bg-gray-800 border-2 border-black inline-block px-2 py-1"
              />
            </div>
          </div>
          <div class="w-full text-right">
            <button
              class="text-red-500 border-red-500"
              :class="{
                'text-gray-300 border-gray-300': saving === mailbox,
                'text-red-500 border-red-500': saved == mailbox,
              }"
              @click="deleteMailbox(mailbox)"
            >
              Löschen</button
            ><button
              v-if="mailbox.id && !mailbox.default"
              :class="{ 'text-gray-300 border-gray-300': testing }"
              @click="setDefault(mailbox)"
            >
              Als Standard festlegen</button
            ><button
              :class="{
                'text-gray-300 border-gray-300': saving === mailbox,
                'text-green-500 border-green-500': saved == mailbox,
              }"
              @click="saveMailbox(mailbox)"
            >
              Speichern
            </button>
          </div>
        </div>
      </div>
      <div class="pt-2 text-center w-full">
        <button class="w-64" @click="addFromGoogle()">
          Google-Postfach hinzufügen
        </button>
        <button
          class="w-64"
          @click="
            editableMailboxes.push({
              authType: 'imap',
              smtp: {},
              imap: {},
              name: '',
            })
          "
        >
          IMAP-Postfach hinzufügen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Tab from "@/components/uicomponents/Tab.vue";
import API from "@/API";
export default {
  name: "Settings",
  props: {
    mailboxes: Array,
  },
  components: {
    Tab,
  },
  emits: ["updateMailboxes"],
  data() {
    return {
      selectedTab: {
        name: "Postfächer",
      },
      editableMailboxes: [],
      testError: "",
      testType: "",
      testing: false,
      tested: {},
      saving: {},
      saved: {},
    };
  },
  methods: {
    addFromGoogle() {
      window.location = process.env.VUE_APP_BE_URL + "/mailboxes/addFromGoogle";
    },
    testMailbox(mailbox, type) {
      if (this.testing) return;
      this.testType = type;
      this.testing = true;
      API.testMailbox(type, mailbox, (res, status) => {
        if (status == 200) {
          this.tested = mailbox;
          if (!res.success) {
            this.testError = res.error;
          }
          setTimeout(() => {
            this.testError = "";
            this.tested = {};
            this.testing = false;
          }, 3000);
        } else {
          this.testing = false;
        }
      });
    },
    setDefault(mailbox) {
      this.editableMailboxes.forEach((m) => {
        m.default = false;
      });
      mailbox.default = true;
      API.saveMailbox({ id: mailbox.id, default: true }, (res, status) => {});
    },
    deleteMailbox(mailbox) {
      API.deleteMailbox(mailbox, (res, status) => {
        if (status == 200) {
          console.log("emitting update");
          this.$emit("updatedMailboxes");
        }
      });
    },
    saveMailbox(mailbox) {
      if (mailbox.name.trim() == "") {
        alert("Name darf nicht leer sein");
        return;
      }
      this.saving = this.mailbox;
      API.saveMailbox(mailbox, (res, status) => {
        this.saving = false;
        if (status == 200) {
          this.$emit("updatedMailboxes");
          this.saved = mailbox;
          setTimeout(() => {
            this.saved = {};
          }, 3000);
        }
      });
    },
  },
  watch: {
    mailboxes: function () {
      this.editableMailboxes = JSON.parse(JSON.stringify(this.mailboxes));
    },
  },
  mounted() {
    this.editableMailboxes = JSON.parse(JSON.stringify(this.mailboxes));
  },
};
</script>

<style>
</style>