<template>
  <div
    class="z-20 w-full h-full absolute text-center top-0 left-0 xs:pt-4 bg-white/40"
  >
    <div class="p-4 shadow-lg w-full h-full xs:h-auto xs:w-5/6 lg:w-1/2 bg-black inline-block text-left rounded">
      <div id="header">
        <span v-if="mail.subject == ''" class="text-lg font-bold"
          >Neue Mail verfassen</span
        >
        <span v-else class="text-lg font-bold">{{ mail.subject }}</span>
        <icon
          icon="xmark"
          class="float-right top-2 right-2 cursor-pointer text-3xl text-white"
          @click="$emit('close')"
        />
        <div class="flex flex-row mt-2">
          <span class="w-16 font-bold mt-1">Von:</span>
          <span
            class="flex-1 bg-black px-2 py-1"
          >{{ mailbox.from_name && mailbox.from_name != mailbox.from_email ? mailbox.from_name+ ' <'+mailbox.from_email+'>' : mailbox.from_email }}</span>
        </div>
        <div class="flex flex-row mt-2">
          <span class="w-16 font-bold mt-1">An:</span>
          <input
            v-model="mail.recipient"
            class="flex-1 bg-gray-800 px-2 py-1"
          />
        </div>
        <div class="flex flex-row mt-2">
          <input
            v-model="mail.subject"
            class="flex-1 bg-gray-800 px-2 py-1"
            placeholder="Betreff"
          />
        </div>
        <div class="flex flex-row mt-2">
          <textarea
            v-model="mail.message"
            rows="10"
            class="flex-1 bg-gray-800 px-2 py-1"
            placeholder=""
          />
        </div>
        <div class="w-full mt-2 text-right">
          <button @click="send()">Senden</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/API";
export default {
  name: "NewMail",
  emits: ["close"],
  props: {
    mailbox: Object,
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  data() {
    return {
      mail: {
        subject: "",
        textbody: "",
        replyTo: "",
        attachments: [],
      },
    };
  },
  methods: {
    send() {
      API.sendMail(this.mailbox.id, this.mail, (res, status) => {
        if (status == 200) {
          this.$emit("close");
        }
      });
    },
  },
};
</script>

<style>
</style>