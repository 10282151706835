import { post } from "./http"

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
export default {
    subscribe: async function subscribe() {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            try {
                const registration = await navigator.serviceWorker.register('/service-worker.js');
                console.log('Service Worker registered with scope:', registration.scope);

                const subscription = await registration.pushManager.getSubscription();

                if (subscription) {
                    return;
                }
                navigator.serviceWorker.register('/service-worker.js').then(function (registration) {
                    const publicVapidKey = 'BI5oRhlv6N2xDKZiku3AM0DtQY2BkA4Fl0miqxjsnITgMpIAWnAungPT-NCpcVGXbTczDXvbWOYQOQMz-jhFONQ';

                    registration.pushManager.getSubscription().then(function (subscription) {
                        if (subscription === null) {
                            registration.pushManager.subscribe({
                                userVisibleOnly: true,
                                applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
                            }).then(function (subscription) {
                                // Send the subscription details to the backend
                                post('/push/subscribe', subscription, (res, status) => {
                                    if (status === 201) {
                                        console.log('Subscription successful');
                                    } else {
                                        console.log('Subscription failed');
                                    }
                                });

                            }).catch(function (error) {
                                console.error('Registration failed:', error);
                            });
                        }
                    });
                }).catch(function (error) {
                    console.error('Service Worker registration failed:', error);
                });
            } catch (error) {
                console.error('Error subscribing to push notifications:', error);
            }
        } else {
            console.warn('Push messaging is not supported');
        }
    },
    unsubscribe: async function unsubscribe() {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            try {
                const registration = await navigator.serviceWorker.register('/service-worker.js');
                console.log('Service Worker registered with scope:', registration.scope);
                const subscription = await registration.pushManager.getSubscription();
                if (subscription) {
                    await subscription.unsubscribe();
                    post('/push/unsubscribe', subscription, (res, status) => {
                        if (status === 200) {
                            console.log('Unsubscription successful');
                        } else {
                            console.log('Unsubscription failed');
                        }
                    });
                }
            } catch (error) {
                console.error('Error unsubscribing from push notifications:', error);
            }
        } else {
            console.warn('Push messaging is not supported');
        }
    }
}