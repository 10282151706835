<template>
  <div class="w-full h-full fixed">
    <Header
      class="h-16"
      :currentMailbox="currentMailbox"
      :mailboxes="mailboxes"
      @toggleSidebar="navigationExpanded = !navigationExpanded"
      @setMailbox="setMailbox"
      @search="updateKeywords"
    ></Header>
    <div class="flex flex-row fixed h-full w-full left-0 top-0 pt-16">
      <Navigation :expanded="navigationExpanded" @toggleNavigation="maybeToggleNavigation" @newMail="maybeToggleNavigation();openNewMailDialog()"></Navigation>
      <div class="flex-1 h-full max-w-full relative" >
        <NewMail v-if="showNewMailDialog" @close="showNewMailDialog = false" :mailbox="currentMailbox" />
        <Inbox v-if="$route.name == 'home' && currentMailbox.id" class="w-full h-full" :currentMailbox="currentMailbox" :keywords="keywords"/>
        <Trash v-if="$route.name == 'trash' && currentMailbox.id" class="w-full h-full" :currentMailbox="currentMailbox" :keywords="keywords" />
        <Sent v-if="$route.name == 'sent' && currentMailbox.id" class="w-full h-full" :currentMailbox="currentMailbox" :keywords="keywords" />
        <Settings v-if="$route.name == 'settings'" :mailboxes="mailboxes" @updatedMailboxes="getMailboxes"/>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/layout/Header.vue";
import Navigation from "@/components/layout/Navigation.vue";
import Inbox from "@/components/home/Inbox.vue";
import Settings from "@/components/settings/Settings.vue";
import Trash from "@/components/home/Trash.vue";
import Sent from "@/components/home/Sent.vue";
import NewMail from "@/components/home/NewMail.vue";
import push from "@/lib/push"
import API from "@/API";

export default {
  name: "HomeView",
  components: {
    Inbox,
    Navigation,
    Header,
    Settings,
    Trash,
    NewMail,
    Sent,
  },
  data() {
    return {
      navigationExpanded: false,
      mailboxes: [],
      currentMailbox: {},
      keywords: "",
      showNewMailDialog: false,
    };
  },
  methods: {
    updateKeywords(keywords) {
      this.keywords = keywords;
    },
    openNewMailDialog(){
      this.showNewMailDialog = true
    },
    getMailboxes() {
      let vm = this;
      API.getMailboxes((res, status) => {
        if (status === 200) {
          push.subscribe();
          this.mailboxes = res;
          try {
            vm.currentMailbox = vm.mailboxes.filter((m) => m.default)[0];
          } catch {}
          if (!vm.currentMailbox) {
            if (vm.mailboxes.length > 0) {
              vm.currentMailbox = vm.mailboxes[0];
            } else {
              vm.currentMailbox = {};
            }
          }
          if(this.$route.name != 'settings' && !vm.currentMailbox.id){
            this.$router.push({name: 'settings'})
          }
        }else if(status === 401){
          this.$router.push({name: 'login'})
        }
      });
    },
    maybeToggleNavigation() {
      if (this.navigationExpanded && window.innerWidth <= 768) {
        this.navigationExpanded = false;
      }
    },
    setMailbox(mailboxId) {
      try {
        this.currentMailbox = this.mailboxes.filter(
          (m) => m.id === mailboxId
        )[0];
      } catch {}
    },
  },
  mounted() {
    this.getMailboxes();
    this.navigationExpanded = window.innerWidth > 1280;
  },
};
</script>
