<template>
  <div
    class="border-b-2 border-primary/50 px-1 py-1 relative"
    :class="{ 'bg-black': mail.read }"
  >
    <div
      v-if="!expand"
      draggable="true"
      @dragstart="$emit('childDrag')"
      class="hover:shadow-sm hover:bg-primary/10 cursor-pointer lg:flex lg:flex-row"
      :class="{ 'font-bold': !mail.read }"
      @click="read()"
    >
      <div class="shrink">
        <span :class="{ block: !expand }">{{ mail.subject }}</span>
        <span
          v-if="$route.name == 'sent'"
          class="w-64 inline-block text-gray-300"
          :class="{ 'font-bold': !mail.read }"
          >{{ mail.recipient }}</span
        >
        <span
          v-else-if="mail.from_name"
          class="w-64 inline-block text-gray-300"
          :class="{ 'font-bold': !mail.read }"
          >{{ mail.from_name }}</span
        >
        <span
          v-else
          class="w-64 pr-4 inline-block text-gray-300"
          :class="{ 'font-bold': !mail.read }"
          >{{ mail.from_email }}</span
        >
      </div>
      <div
        class="lg:pl-2 font-normal text-gray-400 min-w-0 max-h-[2lh] overflow-hidden flex-1 lg:inline-block text-ellipsis break-all"
      >
        {{ mail.textbody }}
      </div>
    </div>
    <div v-if="expand">
      <div
        @click="expand = !expand"
        draggable="true"
        @dragstart="$emit('childDrag')"
        class="cursor-pointer hover:shadow-sm hover:bg-primary/10"
        :class="{ 'font-bold': !mail.read }"
      >
        <span v-if="mail.from_name" class="font-normal inline-block">{{
          mail.from_name
        }}</span>
        <span v-else class="font-normal inline-block">{{
          mail.from_email
        }}</span>
        &nbsp;-&nbsp;<span class="font-normal">{{ mail.subject }}</span>
      </div>
      <SendMail
        v-if="respond"
        :mail="mail"
        @sentMail="
          respond = false;
          expand = false;
        "
      />
      <iframe
        v-if="mail.htmlbody"
        :srcdoc="mail.htmlbody"
        class="text-black bg-white p-2 h-96 w-full break-all"
      ></iframe>
      <div
        v-else
        class="flex-grow text-gray-300 overflow-auto p-2 whitespace-pre-line break-all"
      >
        {{ mail.textbody }}
      </div>
    </div>
    <div class="absolute right-0 top-0">
      <div class="px-2 items-center py-1 bg-black rounded-lg p-1 mt-1">
        <div class="inline-block" v-if="!mail.trash">
          <icon
            class="px-2 cursor-pointer"
            icon="reply"
            @click="toggleResponse()"
          />
          <icon
            class="px-2 cursor-pointer"
            icon="brain"
            @click="analyze()"
            :class="{ 'text-primary': analyzing }"
          />
          <icon class="px-2 cursor-pointer" icon="trash-can" @click="trash()" />
          <icon v-if="mail.replied" class="px-2 text-primary" icon="share" />
          <icon
            v-if="mail.has_attachment"
            class="px-2 text-primary"
            icon="paperclip"
          />
        </div>
        <div class="inline-block" v-else>
          <icon
            class="px-2 cursor-pointer"
            icon="trash-arrow-up"
            @click="restore()"
          />
        </div>
        <span class="align-middle" :class="{ 'font-bold': !mail.read }">{{
          mail.short_date
        }}</span>
      </div>
    </div>
    <div v-if="mail.has_attachment" class="py-1">
      <a
        class="border px-2 py-1 mr-1 border-primary text-primary"
        v-for="attachment in mail.attachments"
        :key="attachment.id"
        target="_blank"
        :href="be_url + '/attachments/' + attachment.id"
        >{{ attachment.name }}</a
      >
    </div>
  </div>
</template>

<script>
import API from "@/API.js";
import SendMail from "@/components/home/SendMail.vue";
export default {
  name: "MailListItem",
  props: {
    mail: Object,
  },
  components: {
    SendMail,
  },
  emits: ["updatedEntry"],
  data() {
    return {
      expand: false,
      respond: false,
      be_url: process.env.VUE_APP_BE_URL,
      analyzing: false,
    };
  },
  methods: {
    read() {
      if (!this.mail.read) {
        API.updateMail(this.mail, { read: true }, () => {});
      }
      this.expand = true;
    },
    toggleResponse() {
      this.respond = !this.respond;
      if (this.respond) this.expand = true;
    },
    trash() {
      API.updateMail(this.mail, { trash: true }, () => {
        this.$emit("updatedEntry");
      });
    },
    restore() {
      API.updateMail(this.mail, { trash: false }, () => {
        this.$emit("updatedEntry");
      });
    },
    analyze() {
      let vm = this;
      let analyzeInt = setInterval(() => {
        vm.analyzing = !vm.analyzing;
      }, 500);
      API.analyzeMail(this.mail, (res, status) => {
        clearInterval(analyzeInt);
        vm.analyzing = false;
        if (status == 200) {
          vm.$emit("updatedEntry");
        }
      });
    },
  },
  mounted() {},
};
</script>