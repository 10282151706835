<template>
  <div
    class="h-full bg-black shrink-0"
    :class="{ 'w-full sm:w-56': expanded, 'w-0 sm:w-16 sm:pl-3': !expanded }"
  >
    <div class="w-full sm:text-center" v-if="expanded" @click="$emit('newMail')">
      <button
        class="border hover:border-2 border-yellow-300 bg-gray-700 px-4 py-2 m-2 font-bold text-yellow-300"
      >
        Neue E-Mail
      </button>
    </div>
    <div class="icondiv p-2 mb-4" v-if="!expanded" @click="$emit('newMail')">
      <icon icon="plus" />
    </div>
    <div
      class="icondiv p-2 hover:text-yellow-200 !block"
      :class="{ '!text-yellow-300': $route.name == 'home' }"
      @click="$emit('toggleNavigation');$router.push('/')"
    >
      <icon icon="inbox" class="mr-2" /><span
        v-if="expanded"
        class="font-bold text-lg align-top mt-0.5 inline-block"
        >Eingang</span
      >
    </div>
    <div
      class="icondiv p-2 hover:text-yellow-200  !block"
      :class="{ '!text-yellow-300': $route.name == 'sent' }"
      @click="$emit('toggleNavigation');$router.push('/sent')"
    >
      <icon icon="paper-plane" class="mr-2" /><span
        v-if="expanded"
        class="font-bold text-lg align-top mt-0.5 inline-block"
        >Ausgang</span
      >
    </div>
    <div
      class="icondiv p-2 hover:text-yellow-200  !block"
      :class="{ '!text-yellow-300': $route.name == 'trash' }"
      @click="$emit('toggleNavigation');$router.push('/trash')"
    >
      <icon icon="trash-can" class="mr-2" /><span
        v-if="expanded"
        class="font-bold text-lg align-top mt-0.5 inline-block"
        >Papierkorb</span
      >
    </div>
    <div id="bottom" class="absolute bottom-0" :class="{ 'block': expanded, 'hidden sm:block': !expanded }">
      <div
        class="icondiv p-2 hover:text-yellow-200"
        :class="{ '!text-yellow-300': $route.name == 'settings' }"
        @click="$emit('toggleNavigation');$router.push('/settings')"
      >
        <icon icon="cog" class="mr-2" /><span
          v-if="expanded"
          class="font-bold text-lg align-top mt-0.5 inline-block"
          >Einstellungen</span
        >
      </div>
      <br />
      <div class="icondiv p-2 hover:text-yellow-200" @click="logout()">
        <icon icon="right-from-bracket" class="mr-2" /><span
          v-if="expanded"
          class="font-bold text-lg align-top mt-0.5 inline-block"
          >Ausloggen</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/API";

export default {
  name: "Navigation",
  emits: ["toggleNavigation","newMail"],
  props: {
    expanded: Boolean,
    mailboxes: Array,
    currentMailbox: Object,
  },
  methods: {
    logout() {
      API.logout((res, status) => {
        if (status == 200 && !res.authenticated) {
          this.$router.push("/login");
        }
      });
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
