<template>
  <div class="bg-black/90 relative">
    <div
      v-if="showEditCategoryPopup"
      class="absolute h-full w-full top-0 left-0 bg-white/30 text-center z-20"
    >
      <div class="inline-block mt-20 p-4 bg-black shadow-lg w-96 relative">
        <span class="font-bold text-lg"
          >Kategorie <span v-if="editedCategory.id">bearbeiten</span
          ><span v-else>erstellen</span></span
        ><br />
        <icon
          icon="xmark"
          class="absolute top-2 right-2 cursor-pointer text-2xl"
          @click="showEditCategoryPopup = false"
        />
        <div class="text-left">
          <label class="font-bold">Name</label><br />
          <input
            class="border-2 border-gray-700 bg-gray-800 text-white px-2 py-1 w-full"
            v-model="editedCategory.name"
            placeholder="Name"
          />
          <label class="font-bold mt-2 inline-block">Beschreibung</label><br />
          <span class="text-sm text-gray-300"
            >Die Beschreibung wird von der KI genutzt, sollte also so
            ausführlich wie nötig sein.</span
          >
          <textarea
            class="border-2 border-gray-700 bg-gray-800 text-white px-2 py-1 w-full"
            v-model="editedCategory.description"
            placeholder="Beschreibung"
          ></textarea>
          <div>
            <input v-model="editedCategory.notifications" type="checkbox" id="categoryNotifications"><label for="categoryNotifications">Benachrichtigungen aktivieren</label>
          </div>
          <div class="w-full text-right">
            <button
              v-if="!editedCategory.id"
              class="border border-primary px-2 py-1 text-primary mt-2"
              @click="modifyCategory()"
            >
              Erstellen
            </button>

            <button
              v-else
              class="border border-primary px-2 py-1 text-primary mt-2"
              @click="modifyCategory()"
            >
              Aktualisieren
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="inboxtabs" class="w-full">
      
      <Tab
        v-for="category in categories" :key="category.id"
        :category="category"
        :selected="currentCategory"
        
        @click="setCategory(category)"
        @edit="editCategory(category)"
        @dragover.prevent
        @drop="drop(category)"
      ></Tab>
      <div
        class="inline-block float-right px-4 py-2 border-b-4 text-white cursor-pointer hover:bg-black border-yellow-100"
        @click="setCategory(currentCategory)"
      >
        <icon icon="refresh" />
      </div>
      <div
        class="inline-block float-right px-4 py-2 border-b-4 text-white cursor-pointer hover:bg-black border-yellow-100"
        @click="markAsRead()"
      >
        <icon icon="envelope-open" />
      </div>
      <div
        class="inline-block px-4 py-2 border-b-4 text-white cursor-pointer hover:bg-black border-yellow-100"
        @click="showCreateCategory()"
      >
        <icon icon="plus" />
      </div>
    </div>
    <div class="h-full pb-11 overflow-auto">
      <MailListItem
        v-for="mail in mails"
        :mail="mail"
        :key="mail"
        @updatedEntry="getMails()"
        @childDrag="dragging = mail"
      />
    </div>
  </div>
</template>

<script>
import Tab from "@/components/uicomponents/Tab.vue";
import MailListItem from "@/components/home/MailListItem.vue";
import API from "@/API";
export default {
  name: "Inbox",
  components: {
    Tab,
    MailListItem,
  },
  props: {
    mailboxes: Array,
    currentMailbox: Object,
    keywords: String
  },
  data() {
    return {
      dragging: {},
      currentCategory: {},
      categories: [],
      showEditCategoryPopup: false,
      editedCategory: {},
      mails: [],
    };
  },
  methods: {
    getMails() {
      let vm = this;
      API.getMails(
        this.currentMailbox.id,
        { category: this.currentCategory.id, search: this.keywords },
        (res, status) => {
          if (status == 200) {
            vm.mails = res;
          }
        }
      );
    },
    markAsRead() {
      for(let mail of this.mails.filter(mail => !mail.read)) {
        this.updateMail(mail, { read: true });
      }
      this.getMails();
    },
    drop(category) {
      this.updateMail(this.dragging, { category: category.id });
      this.setCategory(category);
    },
    updateMail(mail, update) {
      let vm = this;
      API.updateMail(mail, update, (res, status) => {
        if (status == 200) {
          vm.getMails();
        }
      });
    },
    editCategory(category) {
      this.editedCategory = JSON.parse(JSON.stringify(category));
      this.showEditCategoryPopup = true;
    },
    getCategories() {
      let vm = this;
      console.log("getCategories ", this.currentMailbox.id)
      API.getCategories(this.currentMailbox.id, (res, status) => {
        if (status == 200) {
          console.log(res)
          vm.categories = [{ name: "Unsortiert", id: 0 }, ...res];
          vm.currentCategory = vm.categories[0];
          vm.getMails();
        }
      });
    },
    setCategory(category) {
      this.currentCategory = category;
      this.getMails();
    },
    showCreateCategory() {
      this.editedCategory = {};
      this.showEditCategoryPopup = true;
    },
    modifyCategory() {
      let vm = this;
      API.modifyCategory(this.currentMailbox.id, this.editedCategory, (res, status) => {
        if (status == 200) {
          vm.showEditCategoryPopup = false;
          vm.getCategories();
        }
      });
    },
  },
  watch: {
    currentMailbox: function () {
      this.getCategories();
    },
    keywords: function () {
      if(this.categories.length > 0) this.setCategory(this.categories[0]);
    },
  },
  created() {
    console.log(this.categories)
    this.getCategories();
  },
  
};
</script>