<template>
  <div v-if="category" class="inline-block px-4 py-2 border-b-4 text-white cursor-pointer hover:bg-black" :class="{ 'border-primary bg-black font-bold': selected.name===category.name, 'border-yellow-100': selected.name!==category.name }">
    <span>{{category.name}}</span><span v-if="category.unread > 0"> ({{category.unread}})</span>
    <icon v-if="editable && category.name!=='Unsortiert'" icon="pen" class="ml-2 text-sm" @click="$emit('edit')"/>
  </div>
</template>

<script>
export default {
  name: "Tab",
  emits: ["edit"],
  props: {
    category: Object,
    selected: Object,
    editable: {
      type: Boolean,
      default: true
    }
  },
};
</script>