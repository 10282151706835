<template>
  <div class="w-full h-full bg-black text-center">
    <div class="inline-block mt-10 w-1/3">
      <span class="text-white mt-3 inline-block text-4xl font-bold"
        ><span class="text-yellow-300">A</span>Ma<span class="text-yellow-300"
          >i</span
        >l</span
      ><br />
      <img
        src="@/assets/logoyellow.png"
        class="h-40 p-2 inline-block align-top mt-2"
      />
      <div>
        <input v-model="mail" type="email" placeholder="E-Mail Adresse" class=" w-full px-2 py-1 bg-gray-900 border-2 border-gray-700"/><br>
        <input v-model="code" type="text" placeholder="Beta-Code" class=" w-full px-2 py-1 bg-gray-900 border-2 border-gray-700 mt-2"/>
        <div class="text-right w-full">
            <span v-if="error" class="px-2 py-1 border-2 border-red-500 text-red-500 font-bold mt-2 inline-block">Code anfordern fehlgeschlagen!</span>
            <span v-if="success" class="px-2 py-1 border-2 border-green-500 text-green-500 font-bold mt-2 inline-block">Code versendet!</span>
            <button v-if="!error && !success" @click="requestOTT()">Login-Link anfordern</button>
        </div>
        <button class="mt-10" @click="loginWithGoogle()">Login with Google</button>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/API";
import push from "@/lib/push"
export default {
  name: "LoginView",
  data() {
    return {
      mail: "",
      code: "",
      error:"",
      success:""
    };
  },
  methods: {
    loginWithGoogle(){
        window.location=process.env.VUE_APP_BE_URL+"/auth/google/login"
    },
    login() {
      API.login(this.ott, (res, status) => {
        if (status == 200 && res.authenticated) {
          this.$router.push("/");
        }
        else{
            console.log("Login failed")
        }
      });
    },
    requestOTT() {
      API.requestOTT(this.mail, this.code, (res, status) => {
        if (status == 200) {
            if(res.status == "failed"){
                this.error = true
            }
            else{
                this.success = true
            }
            setTimeout(() => {
                this.error = false
                this.success = false
            }, 3000)
        }
      });
    },
  },
  mounted() {
    this.ott = this.$route.query.ott;
    push.unsubscribe();

    if(this.ott){
        this.login()
    }
  },
};
</script>
