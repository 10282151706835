<template>
  <div class="fixed top-0 left-0 w-full bg-black z-10">
    <div class="inline-block w-full">
      <div class="inline-block mr-4">
        <div class="icondiv align-top" @click="expanded=!expanded;$emit('toggleSidebar')">
          <icon class="ml-4 mr-2 mt-5" icon="bars" />
        </div>
        <div class="inline-block" :class="{'!hidden md:inline-block': $route.name != 'settings'}">
          <img
            src="@/assets/logoyellow.png"
            class="h-12 p-2 inline-block align-top mt-2"
          />
          <span class="text-white mt-3 inline-block text-3xl font-bold"
            ><span class="text-yellow-300">A</span>Ma<span
              class="text-yellow-300"
              >i</span
            >l</span
          >
        </div>
      </div>
      <div class="inline-block align-top" v-if="$route.name != 'settings'">
        <input
          type="text"
          v-model="keywords"
          @keyup.enter="$emit('search',keywords)"
          placeholder="Suchen..."
          class="ml-2 md:ml-8 px-3 p-2 border-gray-600 border-2 rounded-full mt-2.5 bg-gray-700 text-white w-56 sm:w-96"
        />
        <icon icon="xmark" class="-ml-8 mt-5 align-top inline-block text-2xl cursor-pointer" @click="keywords='';$emit('search',keywords)"/>
      </div>
      <div class="float-right text-right mt-4 mr-1 font-bold" :class="{'hidden sm:inline-block': !expanded,'inline-block':expanded}">
        <span class="hidden lg:inline-block">Aktuelles Postfach: </span><select class="bg-black text-white" v-model="newCurrentMailbox" @change="$emit('setMailbox', newCurrentMailbox)">
          <option class="bg-black text-white"
            v-for="mailbox in mailboxes"
            :key="mailbox.id"
            :value="mailbox.id"
          >
            {{ mailbox.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  emits: ["toggleSidebar", "setMailbox","search"],
  props: {
    currentMailbox: Object,
    mailboxes: Array,
  },
  data() {
    return {
      keywords: "",
      expanded: false,
      newCurrentMailbox: "Bitte auswählen...",
    }
  },
  watch: {
    currentMailbox: function () {
      this.newCurrentMailbox = this.currentMailbox.id
    },
  },
};
</script>
